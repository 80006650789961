import {
  Grid,
  Dropdown,
  Avatar,
  Divider,
  TargetLogo,
  Button,
  Tooltip,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisV,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import { useHeader } from './useHeader'
import { SkipLinks } from './SkipLinks'
import { checkUser } from '../../../utills/Common'
import HeaderBreadcrumb from './HeaderBreadcrumb'
import SessionHandler from '../../Auth/SessionHandler'
import { useFirefly } from '../../../utills/useAnalytics'
import linkConfig from '../../../config/linkConfig'

/* Selectors */
import { useHome } from '../../../pages/Home/useHome'

export const VMHeader = ({
  isSideNavOpen,
  onRequestClose,
  auth,
  showNav,
  setIsDarkMode,
  isDarkMode,
}) => {
  const { isAuthenticated, session } = auth
  const { firstName, lastName } = (session && session.userInfo) || {}
  const user = checkUser(session)
  const { options, handleClose } = useHeader(auth)

  const { handleFireflyClickEvent } = useFirefly()
  const { pendingAction } = useHome()

  const checkIfNotCandidate = (data) => {
    if (
      data?.value?.supplier_type_status &&
      data?.value?.supplier_type_status !== 'Candidate'
    ) {
      return true
    }
    return false
  }

  let pendingActionArray =
    pendingAction &&
    Object.entries(pendingAction).map(([key, value]) => ({ key, value }))

  let onlyCandidate = !pendingActionArray?.some(checkIfNotCandidate)

  let polTrainingLink = linkConfig.pol
  if (onlyCandidate) {
    polTrainingLink = polTrainingLink + 'candidate/applications/3336'
  } else {
    polTrainingLink =
      polTrainingLink + 'sites/ogrp-tts-polapplications/Pages/3347.aspx'
  }

  return (
    <Grid.Container spacing="none" justify="space-between" align="center">
      <SkipLinks
        isSideNavOpen={isSideNavOpen}
        onRequestClose={onRequestClose}
        showNav={showNav}
      />
      <Grid.Item xs={2}>
        <Grid.Container spacing="none">
          <Grid.Item xs={11} className="hc-pa-min sm_flex">
            {/* {isAuthenticated() && !user.isUser && (
              <span className="C-Layout__menuButton">
                <Button
                  iconOnly
                  onClick={onRequestClose}
                  className="hc-ml-dense vmm_flex_center "
                  araia-label={
                    isSideNavOpen
                      ? 'Close side navigation'
                      : 'Open side navigation'
                  }
                >
                  <FontAwesomeIcon icon={faBars} />
                </Button>
              </span>
            )} */}
            <Link
              to="/"
              exact="true"
              className="vmm_flex_center"
              onClick={() => {
                handleFireflyClickEvent('Home')
              }}
            >
              <TargetLogo
                size="expanded"
                className="hc-mr-normal"
                color="white"
              />
              <span className="hc-fs-section hc-clr-grey06 hide_for_tablet hide_for_mobile font-force-white">
                Supplier Management
              </span>
            </Link>
          </Grid.Item>
          <Grid.Item xs={1} className="sm_header sm_flex">
            <Divider direction="vertical" className="hc-pr-normal" />
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      <Grid.Item xs={6} className="hc-pa-min">
        <div className="hc-fs-section hc-clr-grey05">
          <HeaderBreadcrumb />
        </div>
      </Grid.Item>
      <Grid.Item xs={3} className="hc-ta-right">
        {isAuthenticated() && (
          <>
            <SessionHandler />
            {firstName && lastName && (
              <>
                <span className="vm_header_user hc-pr-normal">
                  {user.isUser && (
                    <Tooltip
                      content="Training & Support on POL"
                      location="bottom"
                      className="training-header"
                      tabIndex={-1}
                    >
                      <a
                        href={polTrainingLink}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(eve) => {
                          handleFireflyClickEvent()
                        }}
                      >
                        {/* <Avatar
                          className="hc-bg-contrast fullscreen-header"
                          style={{ border: '1px solid #707070' }}
                          aria-label="Click to launch training and support"
                        >
                          <FontAwesomeIcon icon={faQuestionCircle} />
                          <span className="hc-sr-only">
                            Click launch Training & Support on POL
                          </span>
                        </Avatar> */}
                        <div>
                          <span
                            className="font-force-white"
                            style={{ fontSize: '14px' }}
                          >
                            Training
                          </span>
                          <FontAwesomeIcon
                            icon={faExternalLinkAlt}
                            style={{ marginLeft: '5px' }}
                          />
                        </div>
                      </a>
                    </Tooltip>
                  )}

                  <Avatar
                    aria-label="Account of current user"
                    normal="true"
                    className="hc-ml-dense hc-mr-dense hc-bg-contrast-weak hc-clr-contrast"
                  >
                    {firstName.charAt(0) + lastName.charAt(0)}
                  </Avatar>
                  <p
                    className="hc-clr-contrast-weak hc-fs-caption hc-ta-left hide_for_mobile "
                    style={{ maxWidth: 120 }}
                  >
                    {user.isUser && <></>}
                    <span className="vm_display_block vm-text-ellipsis font-force-white">
                      {firstName} {lastName}
                    </span>
                    <span className="vm_display_block vm-text-ellipsis font-force-white">
                      {user.isUser && user.companyName ? user.companyName : ''}
                    </span>
                  </p>
                </span>
                {user.isUser && (
                  <Dropdown location="bottom-left" className="vm-header-menu">
                    <Button iconOnly aria-label="Open dropdown">
                      <FontAwesomeIcon icon={faEllipsisV} />
                      <span className="hc-sr-only">Account menu</span>
                    </Button>
                    <Dropdown.Menu style={{ marginLeft: '-16em' }}>
                      <Dropdown.MenuItem
                        onClick={(event) => setIsDarkMode(!isDarkMode)}
                        style={{
                          height: '45px',
                        }}
                      >
                        <Input.Toggle
                          id="theme_toggle"
                          label="Dark Mode"
                          value={isDarkMode}
                        />
                      </Dropdown.MenuItem>
                      {options.map((option) => (
                        <Dropdown.MenuItem
                          key={`${option}`}
                          onClick={handleClose}
                        >
                          {option}
                        </Dropdown.MenuItem>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </>
            )}
          </>
        )}
      </Grid.Item>
    </Grid.Container>
  )
}

export default VMHeader
