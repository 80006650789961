import axios from 'axios'
import * as ACTIONS from './actionType'
import { Notification } from '../../store/Notification/actionCreator'
import apiConfig from '../../config/apiConfig'
import {
  MATRICES_REQUIRED_SUPPLIER_TYPE,
  TRANSPORTATION_TYPES,
} from '../../constants/common'
import {
  brandClassification,
  brandId,
  brandName,
  brandStatus,
  brandType,
} from '../../pages/Brand/constants/brandConstants'

export const getLocationDashboard = (queryType) => {
  let url = `${
    apiConfig.api.location
  }internal_location_dashboards?filter_type=${queryType || 'All'}`

  if (queryType === 'security') {
    url = `${apiConfig.api.location}security_review_dashboards`
  }

  return (dispatch) => {
    dispatch(getLocationStart())
    axios
      .get(`${url}`)
      .then((response) => {
        const { data } = response

        dispatch(getLocationSuccess(data))
        return data
      })
      .catch((error) => {
        dispatch(getLocationFailure(error.response?.data?.meta_info?.error))
      })
  }
}

const getLocationStart = () => ({
  type: ACTIONS.TYPE.SUPPLIER_LOCATION_INIT,
})

const getLocationSuccess = (data) => ({
  type: ACTIONS.TYPE.SUPPLIER_LOCATION_INFO,
  data,
})

const getLocationFailure = (error) => ({
  type: ACTIONS.TYPE.SUPPLIER_LOCATION_FAIL,
  payload: { error },
})

export const getSupplierList = () => {
  const url = `${apiConfig.api.supplier}internal_suppliers`
  return (dispatch) => {
    dispatch(getSupplierListStart())
    axios
      .get(`${url}`)
      .then((response) => {
        const { data } = response
        dispatch(getSupplierListSuccess(data.content))
        return data
      })
      .catch((error) => {
        dispatch(getSupplierListFailure(error.response?.data?.meta_info?.error))
      })
  }
}

const getSupplierListStart = () => ({
  type: ACTIONS.TYPE.SUPPLIER_LIST_INIT,
})

const getSupplierListSuccess = (data) => ({
  type: ACTIONS.TYPE.SUPPLIER_LIST_INFO,
  data,
})

const getSupplierListFailure = (error) => ({
  type: ACTIONS.TYPE.SUPPLIER_LIST_FAIL,
  payload: { error },
})
/*
export const getSupplierList = () => {
  const url = `${apiConfig.api.supplier}internal/suppliers`
  return function (dispatch) {
    dispatch({ type: ACTIONS.TYPE.SUPPLIER_LIST_INIT })
    return axios
      .get(url)
      .then((response) => {
        const data = response.data
        // const data = Invites
        dispatch({ type: ACTIONS.TYPE.SUPPLIER_LIST_INFO, data })
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.TYPE.SUPPLIER_LIST_FAIL, error })
      })
  }
}
*/

const getDepartments = () => {
  return axios.get(`${apiConfig.api.masterData}departments`)
}
const getSupplierTypes = () => {
  return axios.get(`${apiConfig.api.masterData}supplier_types`)
}
const getSupplierSubTypes = () => {
  return axios.get(`${apiConfig.api.masterData}supplier_subtypes`)
}
const getMatrices = () => {
  return axios.get(`${apiConfig.api.masterData}supplier_matrices`)
}
const getProductTypes = () => {
  return axios.get(`${apiConfig.api.masterData}product_types`)
}
const getCapabilitiesFromMvs = () => {
  return axios.get(
    `${apiConfig.api.external}mvs_capability_details/master/null`
  )
}

export const getInviteDetails = (dispatch, supplierId) => {
  return function (dispatch) {
    dispatch(inviteDetailsInitiated())
    return axios
      .all([
        getDepartments(),
        getSupplierTypes(),
        getSupplierSubTypes(),
        getMatrices(),
        getProductTypes(),
        getCapabilitiesFromMvs(),
      ])
      .then(
        axios.spread(function (
          departmentsResponse,
          supplierTypesResponse,
          supplierSubTypesResponse,
          matricesResponse,
          productsResponse,
          capabilityResponse
        ) {
          mergeMvsCapabilitiesWithMatrix(capabilityResponse, matricesResponse)
          dispatch(
            inviteDetailsSuccess(
              departmentsResponse.data,
              supplierTypesResponse.data,
              supplierSubTypesResponse.data,
              matricesResponse.data,
              productsResponse.data
            )
          )
        })
      )
      .catch((error) => {
        dispatch(inviteDetailsFail(error))
      })
  }
}

const mergeMvsCapabilitiesWithMatrix = (
  capabilityResponse,
  matricesResponse
) => {
  removeExistingCapabilities(capabilityResponse, matricesResponse)
  appendNewCapabilities(capabilityResponse, matricesResponse)
}

const appendNewCapabilities = (capabilityResponse, matricesResponse) => {
  capabilityResponse?.data?.forEach((supplierType) => {
    supplierType?.capabilities?.forEach((capability) => {
      matricesResponse?.data?.push({
        matrix_id: capability.type_id,
        matrix_name: capability.type_name,
        supp_type_id: supplierType.type_id,
      })
    })
  })
}

const removeExistingCapabilities = (capabilityResponse, matricesResponse) => {
  let removedArray = []
  matricesResponse?.data?.forEach((matrix) => {
    let found = capabilityResponse?.data?.some((capability) => {
      return capability.type_id === String(matrix.supp_type_id)
    })
    if (!found) {
      removedArray.push(matrix)
    }
  })
  matricesResponse.data = removedArray
}

const inviteDetailsInitiated = (data) => {
  return {
    type: ACTIONS.TYPE.SUPPLIER_INVITE_INIT,
    data,
  }
}
const inviteDetailsSuccess = (
  departmentsData,
  supplierTypesData,
  supplierSubTypesData,
  matricesData,
  productsData
) => {
  return {
    type: ACTIONS.TYPE.SUPPLIER_INVITE_INFO,
    departmentsData,
    supplierTypesData,
    supplierSubTypesData,
    matricesData,
    productsData,
  }
}
const inviteDetailsFail = (error) => {
  return {
    type: ACTIONS.TYPE.SUPPLIER_INVITE_FAIL,
    error,
  }
}
/***********************************/

export function submitInviteRequest(payload, type, inviteId) {
  const {
    supplier_name,
    supplier_types,
    checkedList,
    matrixList,
    productList,
    relationship_manager,
    email,
    additional_notes,
    departments,
    generic_mail_box,
    carrierType,
    sourced_by,
  } = payload
  const supType = Array.isArray(supplier_types)
    ? supplier_types
    : [supplier_types]

  const productMatrix = productList.map((index) => {
    return {
      id: index.product_type_id,
      value: index.product_type_name,
      supp_sub_type_id: index.supp_sub_type_id,
    }
  })
  const mapping = supType.map(({ id }) => {
    return {
      id: id,
      supplier_sub_types: checkedList
        .filter((el) => el.supp_type_id === id)
        .map(({ supp_sub_type_id: id }) => ({
          id,
          product_types: productMatrix.filter(
            (el) => el.supp_sub_type_id === id
          ),
        })),
    }
  })
  const mappingMatrix = matrixList.map((index) => {
    return {
      id: index.matrix_id,
      value: index.matrix_name,
    }
  })

  const departmentVal = [departments].map((item) => {
    return item.id === null ? null : item.id
  })
  let data = {}
  if (supType[0]?.id === 8 || supType[0]?.id === 6) {
    data = {
      relationship_manager: {
        ...relationship_manager,
        dept_id: departmentVal.toString(),
      },
      email: email,
      generic_mail_box: generic_mail_box,
      save_or_send: type,
      supplier_name: supplier_name,
      additional_notes: additional_notes,
      // departments: [departments].map(({ id }) => ({ id, })),
      departments: [departments].map(({ id, label: value }) => ({
        id,
        value,
      })),
      distributor: payload?.distributor === 'Yes' ? true : false,
      distributor_type: payload?.distributor_type,
      supplier_types: mapping,
    }
  } else if (
    MATRICES_REQUIRED_SUPPLIER_TYPE.includes(supType[0].id) ||
    TRANSPORTATION_TYPES.includes(supType[0].id)
  ) {
    data = {
      relationship_manager: {
        ...relationship_manager,
        dept_id: departmentVal.toString(),
      },
      email: email,
      generic_mail_box: generic_mail_box,
      save_or_send: type,
      supplier_name: supplier_name,
      additional_notes: additional_notes,
      // departments: [departments].map(({ id }) => ({ id, })),
      departments: [],
      distributor: false,
      distributor_type: '',
      supplier_types: mapping,
      supplier_matrices: mappingMatrix,
      carrier_types: carrierType,
      sourced_by: sourced_by,
    }
  } else {
    data = {
      relationship_manager: {
        ...relationship_manager,
        dept_id: departmentVal.toString(),
      },
      email: email,
      generic_mail_box: generic_mail_box,
      save_or_send: type,
      supplier_name: supplier_name,
      additional_notes: additional_notes,
      departments: [],
      distributor: false,
      distributor_type: '',
      supplier_types: mapping,
    }
  }
  inviteId && (data = { ...data, invite_id: inviteId })
  payload.primary_email &&
    (data = { ...data, primary_email: payload.primary_email })

  return function (dispatch) {
    dispatch(postInviteInitiated())
    const url = `${apiConfig.api.supplier}invites`
    return axios
      .post(url, data)
      .then((response) => {
        const { data } = response
        if (data) {
          // dispatch(postInviteSuccess(Invite))
          dispatch(postInviteSuccess(response.data))
        } else {
          dispatch(postInviteFail(true))
        }
      })
      .catch((e) => {
        dispatch(postInviteFail(e))
      })
  }
}

export function submitAddSupplierTypeRequest(payload) {
  const {
    supplier_id,
    supplier_types,
    checkedList,
    matrixList,
    productList,
    departments,
    carrierType,
    sourced_by,
  } = payload
  const supType = supplier_types?.id

  const productMatrix = productList.map((index) => {
    return {
      id: index.product_type_id,
      value: index.product_type_name,
      supp_sub_type_id: index.supp_sub_type_id,
    }
  })
  const mapping = {
    id: supplier_types?.id,
    value: supplier_types?.label,
    supplier_sub_types: checkedList
      .filter((el) => el.supp_type_id === supplier_types.id)
      .map(({ supp_sub_type_id: id }) => ({
        id,
        product_types: productMatrix.filter((el) => el.supp_sub_type_id === id),
      })),
    supplier_matrices: matrixList.map((index) => {
      return {
        id: index.matrix_id,
        value: index.matrix_name,
      }
    }),
    sourced_by: sourced_by,
    carrier_types: carrierType,
  }

  let data = {}
  if (supType === 8 || supType === 6) {
    data = {
      supplier_id: supplier_id,
      departments: [departments].map(({ id, label: value }) => ({
        id,
        value,
      })),
      supplier_type: mapping,
    }
  } else if (
    MATRICES_REQUIRED_SUPPLIER_TYPE.includes(supType) ||
    TRANSPORTATION_TYPES.includes(supType)
  ) {
    data = {
      supplier_id: supplier_id,
      supplier_type: mapping,
      supplier_matrices: matrixList.map((index) => {
        return {
          id: index.matrix_id,
          value: index.matrix_name,
        }
      }),
      carrier_types: carrierType,
      sourced_by: sourced_by,
    }
  } else {
    data = {
      supplier_id: supplier_id,
      departments: [],
      supplier_type: mapping,
    }
  }

  return function (dispatch) {
    dispatch(postInviteInitiated())
    const url = `${apiConfig.api.supplier}add_supplier_type/${supplier_id}`
    return axios
      .put(url, data)
      .then((response) => {
        const { data } = response
        if (data) {
          // dispatch(postInviteSuccess(Invite))
          dispatch(
            Notification(
              true,
              'Supplier Added Successfully. Please give few mins to reflect change',
              false
            )
          )
        } else {
          dispatch(postInviteFail(true))
        }
      })
      .catch((e) => {
        dispatch(postInviteFail(e))
      })
  }
}

export const updateBrandFilter = (id, value) => {
  switch (id) {
    case brandId.id:
      return {
        type: ACTIONS.TYPE.BRAND_ID_FILTER,
        value,
      }
    case brandName.id:
      return {
        type: ACTIONS.TYPE.BRAND_NAME_FILTER,
        value,
      }
    case brandType.id:
      return {
        type: ACTIONS.TYPE.BRAND_TYPE_FILTER,
        value,
      }
    case brandClassification.id:
      return {
        type: ACTIONS.TYPE.BRAND_CLASSIFICATION_FILTER,
        value,
      }
    case brandStatus.id:
      return {
        type: ACTIONS.TYPE.BRAND_STATUS_FILTER,
        value,
      }
    default:
      break
  }
}

export const setBrandPageSize = (value) => {
  return {
    type: ACTIONS.TYPE.BRAND_PAGE_SIZE,
    data: value,
  }
}

export const setBrandPageNumber = (value) => {
  return {
    type: ACTIONS.TYPE.BRAND_PAGE_NUMBER,
    data: value,
  }
}

export const clearBrandFilter = () => {
  return {
    type: ACTIONS.TYPE.CLEAR_BRAND_FILTER,
  }
}
const postInviteInitiated = (data) => {
  return {
    type: ACTIONS.TYPE.SUPPLIER_POST_INVITE_INIT,
    data,
  }
}
const postInviteSuccess = (data) => {
  return {
    type: ACTIONS.TYPE.SUPPLIER_POST_INVITE_INFO,
    data,
  }
}
const postInviteFail = (error) => {
  return {
    type: ACTIONS.TYPE.SUPPLIER_POST_INVITE_FAIL,
    error,
  }
}
/***/
export const fetchLocationSearchOptions = (data) => {
  return {
    type: ACTIONS.TYPE.LOCATION_SEARCH_OPTIONS_INFO,
    data,
  }
}

export const resetLocationSearchOption = () => {
  return {
    type: ACTIONS.TYPE.LOCATION_SEARCH_OPTIONS_RESET,
  }
}
export const getInvite = (dispatch, inviteId) => {
  const url = `${apiConfig.api.supplier}invites/${inviteId}`
  return function (dispatch) {
    dispatch(getInviteInitiated())
    return axios
      .get(url)
      .then((response) => {
        dispatch(getInviteSuccess(response.data))
      })
      .catch((e) => {
        dispatch(getInviteFail(e))
      })
  }
}

const getInviteInitiated = (data) => {
  return {
    type: ACTIONS.TYPE.SUPPLIER_USER_INVITE_INIT,
    data,
  }
}
const getInviteSuccess = (data) => {
  return {
    type: ACTIONS.TYPE.SUPPLIER_USER_INVITE_INFO,
    data,
  }
}
const getInviteFail = (error) => {
  return {
    type: ACTIONS.TYPE.SUPPLIER_USER_INVITE_FAIL,
    error,
  }
}

export const getAllInvite = () => {
  const url = `${apiConfig.api.supplier}invites`
  return function (dispatch) {
    dispatch({ type: ACTIONS.TYPE.SUPPLIER_INVITE_LIST_INIT })
    return axios
      .get(url)
      .then((response) => {
        const data = response.data
        // const data = Invites
        dispatch({ type: ACTIONS.TYPE.SUPPLIER_INVITE_LIST_INFO, data })
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.TYPE.SUPPLIER_INVITE_LIST_FAIL, error })
      })
  }
}

export const getAllBrand = (dispatch, brandFilter) => {
  let url = `${apiConfig.api.brand}brands`
  url = addBrandFilterParams(url, brandFilter)
  return async function (dispatch) {
    dispatch({ type: ACTIONS.TYPE.BRAND_LIST_INIT })
    try {
      const response = await axios.get(url)
      const data = response.data?.['content']
      dispatch({ type: ACTIONS.TYPE.BRAND_LIST_INFO, data })
    } catch (error) {
      dispatch({ type: ACTIONS.TYPE.BRAND_LIST_FAIL, error })
    }
  }
}

export const resetInviteList = (dispatch) => {
  dispatch({ type: ACTIONS.TYPE.SUPPLIER_INVITE_LIST_RESET })
}

export const fetchTypeAheadData = async (param = {}) => {
  const typeAheadResponse = await axios.post(
    `${apiConfig.api.contact}search`,
    param
  )

  return typeAheadResponse?.data
}

export const fetchSupplyChainWorklist = async () => {
  try {
    const response = await axios.get(
      `${apiConfig.api.aggregator}worklist/supply_chain_certs`
    )

    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchDunDetails = async (dunsNumber, inviteId) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.supplier}duplicates?filter_by=duns&filter_value=${dunsNumber}&invite_id=${inviteId}`
    )

    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchDuplicateSupplierNames = async (supplierName) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.supplier}suppliers/duplicate_names?supplierName=${supplierName}`
    )

    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const saveDunsReview = (dunsReviewObj) => {
  return async () => {
    try {
      await axios.post(
        `${apiConfig.api.supplier}dnb-matches/save-review`,
        dunsReviewObj
      )
      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export const preRegistrationDetails = (reqBody) => {
  return async () => {
    try {
      await axios.post(`${apiConfig.api.supplier}pre-registration`, reqBody)
      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export const createSupplierWithoutDuns = (reqBody) => {
  return async () => {
    try {
      await axios.post(
        `${apiConfig.api.supplier}register_without_duns`,
        reqBody
      )
      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export const saveDunsNumber = (dunsNumber) => {
  return async () => {
    try {
      // const response = await axios.get(`${apiConfig.api.supplier}dnb-matches/validate-duns/${dunsNumber}`)050471416
      const response = await axios.get(
        `${apiConfig.api.supplier}dnb-matches/validate-duns/${dunsNumber}`
      )
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const getDuplicateSuppliers = (reqBody) => {
  return async () => {
    try {
      const response = await axios.post(
        `${apiConfig.api.supplier}fuzzy_search/duplicate_suppliers`,
        reqBody
      )
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const getSupplierReviewDetail = (getSelectedSupplier) => {
  return async () => {
    try {
      const response = await axios.get(
        `${apiConfig.api.supplier}fuzzy_search/review_suppliers/${getSelectedSupplier}`
      )
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const getSupplierDetailsFromMail = async (supplierId, email) => {
  try {
    const response = await axios.post(
      `${apiConfig.api.supplier}supplier_relationship/search?email_id=${email}`
    )
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const initiateNewRelationship = (relationshipCreateReq) => {
  return function (dispatch) {
    return axios
      .post(
        `${apiConfig.api.supplier}supplier_relationship/initiate`,
        relationshipCreateReq
      )
      .then((response) => {
        if (response?.data?.success) {
          dispatch(
            Notification(true, 'Thank you,Relationship request Initiated!!!')
          )
        } else {
          dispatch(Notification(true, response?.data?.message, true))
        }
      })
  }
}

export const saveRelationshpReview = (relationshipReviewOut) => {
  return function (dispatch) {
    return axios
      .post(
        `${apiConfig.api.supplier}supplier_relationship/save_review`,
        relationshipReviewOut
      )
      .then((response) => {
        if (response) {
          dispatch(Notification(true, 'Thank you,Review Sucessful!!!'))
        } else {
          dispatch(
            Notification(
              true,
              'Sorry, Request Failed.Please contact admin',
              true
            )
          )
        }
      })
  }
}

export const getReviewRelationshipDetails = (supplierId, relationId) => {
  try {
    const response = axios.get(
      `${apiConfig.api.supplier}supplier_relationship/get_review/${supplierId}/${relationId}`
    )

    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getRelationshipContactDetails = (sharedSupplierId, supplierId) => {
  try {
    const response = axios.get(
      `${apiConfig.api.contact}shared_contacts?shared_supplier_id=${supplierId}&supplier_id=${sharedSupplierId}`
    )

    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const saveRelationshpContacts = (shareContacts) => {
  return function (dispatch) {
    return axios
      .post(`${apiConfig.api.contact}shared_contacts/initiate`, shareContacts)
      .then((response) => {
        if (response?.data?.success) {
          dispatch(Notification(true, 'All Contacts got shared !!!'))
        } else {
          dispatch(
            Notification(
              true,
              'Sorry, Request Failed.Please contact admin',
              true
            )
          )
        }
      })
  }
}

export const deleteRelationship = (suppId, relId) => {
  return function (dispatch) {
    return axios
      .delete(
        `${apiConfig.api.supplier}delete_relationships?supplier_id=${suppId}&relation_id=${relId}`
      )
      .then((response) => {
        if (response?.data?.status) {
          dispatch(Notification(true, 'Relationship deleted Successfully !!!'))
        } else {
          dispatch(
            Notification(
              true,
              'Sorry, Request Failed.Please contact admin',
              true
            )
          )
        }
      })
  }
}

export const deleteInvite = (inviteId) => {
  return function (dispatch) {
    return axios
      .delete(`${apiConfig.api.supplier}invites/${inviteId}`)
      .then((response) => {
        if (response?.data) {
          dispatch(getAllInvite(dispatch))
          dispatch(Notification(true, 'Invite deleted Successfully'))
        } else {
          dispatch(
            Notification(
              true,
              'Sorry, Request Failed.Please contact admin',
              true
            )
          )
        }
      })
  }
}

export const expireInvite = (inviteId) => {
  return function (dispatch) {
    return axios
      .put(`${apiConfig.api.supplier}expire_invites/${inviteId}`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(getAllInvite(dispatch))
          dispatch(Notification(true, 'Invite expired Successfully'))
        } else {
          dispatch(
            Notification(
              true,
              'Sorry, Request Failed.Please contact admin',
              true
            )
          )
        }
      })
  }
}

export const getDiversityQuestionnaire = (country, supplierTypes) => {
  if (supplierTypes?.length === 0) {
    supplierTypes = 0
  }

  try {
    const response = axios.get(
      `${apiConfig.api.masterData}supplier_diversities?countryCode=${country}&supplier_types_ids=${supplierTypes}`
    )

    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const saveDiversityQuestionnaireExternal = (suppId, answerList) => {
  try {
    const response = axios.post(
      `${apiConfig.api.supplier}external/supplier_diversities/${suppId}`,
      answerList
    )
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const saveDiversityQuestionnaireInternal = (suppId, answerList) => {
  try {
    const response = axios.post(
      `${apiConfig.api.supplier}internal/supplier_diversities/${suppId}`,
      answerList
    )
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const saveDiversityAffidavitExternal = (suppId, answerList) => {
  try {
    const response = axios.post(
      `${apiConfig.api.supplier}external/supplier_diversities/affidavit/${suppId}`,
      answerList
    )
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const editDunsRequests = () => {
  let url = `${apiConfig.api.supplier}edit_duns_requests`

  return (dispatch) => {
    dispatch(getEditDunsStart())
    axios
      .get(`${url}`)
      .then((response) => {
        const { data } = response
        dispatch(getEditDunsSuccess(data))
        return data
      })
      .catch((error) => {
        dispatch(getEditDunsFailure(error.response?.data?.meta_info?.error))
      })
  }
}

const getEditDunsStart = () => ({
  type: ACTIONS.TYPE.EDIT_DUNS_INIT,
})

const getEditDunsSuccess = (data) => ({
  type: ACTIONS.TYPE.EDIT_DUNS_INFO,
  data,
})

const getEditDunsFailure = (error) => ({
  type: ACTIONS.TYPE.EDIT_DUNS_FAIL,
  payload: { error },
})

export function confirmDunsUpdate(supplierId, isApproved, dispatch) {
  return axios
    .put(
      `${apiConfig.api.supplier}approve_reject_duns?supplier_id=${supplierId}&is_approved=${isApproved}`
    )
    .then(() => {
      dispatch(editDunsRequests())
      if (isApproved) {
        dispatch(
          Notification(
            true,
            'Request Approved. Changes will be reflected in few mins'
          )
        )
      } else {
        dispatch(Notification(true, 'Request Declined Successfully!!!'))
      }
    })
    .catch(() => {})
}

// export const getSCACDetails = (scacCode) => {
//   try {
//     const response = axios.get(
//       `${apiConfig.api.masterData}getSCACDetails/${scacCode}`
//     )
//     return Promise.resolve(response)
//   } catch (err) {
//     return Promise.resolve(err)
//   }
// }

/** Helper Fucntions */

const addBrandFilterParams = (url, brandFilter) => {
  url += `?size=${brandFilter?.brandPageSize}`
  url += `&page=${brandFilter?.brandPageNumber}`
  if (brandFilter?.brandId) {
    url += `&brand_id=${brandFilter?.brandId}`
  }
  if (brandFilter?.brandName) {
    url += `&brand_name=${brandFilter?.brandName}`
  }
  if (brandFilter?.brandType) {
    url += `&brand_type=${brandFilter?.brandType?.value}`
  }
  if (brandFilter?.brandClassification) {
    url += `&brand_classification=${brandFilter?.brandClassification?.value}`
  }
  if (brandFilter?.brandStatus) {
    url += `&brand_status=${brandFilter?.brandStatus?.value}`
  }
  return url
}
