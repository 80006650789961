/**
 * @enum
 * To determine which component is loading in Add Location, in order to show skeleton loading accoring to the current loading component
 */
export const LOADING_COMPONENT = {
  COUNTRY: 'COUNTRY',
  ZIPCODE: 'ZIPCODE',
  ADDRESS_INTERACTIVE: 'ADDRESS_INTERACTIVE',
  ADDRESS_INTERACTIVE_CTA: 'ADDRESS_INTERACTIVE_CTA',
  LOCATION_INFO: 'LOCATION_INFO',
  LOCATION_CAPABILITY: 'LOCATION_CAPABILITY',
  SAVE_AND_ADD_CTA: 'SAVE_AND_ADD_CTA',
  SAVE_AND_CLOSE_CTA: 'SAVE_AND_CLOSE_CTA',
  DVS_DETAILS: 'DVS_DETAILS',
  PREPAID_DETAILS: 'PREPAID_DETAILS',
  OTHER_INFO: 'OTHER_INFO',
  SAVE_CTA: 'SAVE_CTA',
}

/**
 * @enum
 * To determine which component is currently visible in Add Location Page
 */
export const VISIBLE_COMPONENT = {
  COUNTRY: 'COUNTRY',
  ADDRESS_INTERACTIVE: 'ADDRESS_INTERACTIVE',
  DUPLICATE_LOCATION_MODAL: 'DUPLICATE_LOCATION_MODAL',
  VALIDATION_MSG: 'VALIDATION_MSG',
  ADDRESS_MAP: 'ADDRESS_MAP',
  LOCATION_INFO: 'LOCATION_INFO',
  LOCATION_CAPABILITY: 'LOCATION_CAPABILITY',
  DVS_DETAILS: 'DVS_DETAILS',
  // GENERAL_ORDERING_INFO: 'GENERAL_ORDERING_INFO',
  // HOURS_OF_OPERATION: 'HOURS_OF_OPERATION',
  // HOLIDAY_CLOSURES: 'HOLIDAY_CLOSURES',
  OTHER_INFO: 'OTHER_INFO',
}

/**
 * @enum
 * To determine which component is currently visible in Edit Location Page
 */
export const VISIBLE_COMPONENT_EDIT = {
  DUPLICATE_LOCATION_MODAL: 'DUPLICATE_LOCATION_MODAL',
}

export const locationInfoIds = {
  saveAndAddCta: 'saveAndAdd',
  saveAndCloseCta: 'saveAndClose',
  locationNameForm: 'locationName',
  locationTypeForm: 'locationType',
  locationTypeFormReview: 'locationTypeReview',
  reviewModalClose: 'reviewModalClose',
}

/**
 * @enum
 * List of all available pages to determine which page is currently visible
 */
export const PAGE_TYPE = {
  ADD_LOCATION: 'ADD_LOCATION',
  EDIT_EXISTING_LOCATION: 'EDIT_EXISTING_LOCATION',
  ADDRESS_SUGGESTION: 'ADDRESS_SUGGESTION',
  VIEW_LOCATION: 'VIEW_LOCATION',
  LOCATION_READY: 'LOCATION_READY',
  EDIT_LOCATION: 'EDIT_LOCATION',
  EDIT_ADDRESS: 'EDIT_ADDRESS',
  LOCATION_TYPE_CAPABILITY: 'LOCATION_TYPE_CAPABILITY',
  SHIPPING_DETAILS: 'SHIPPING_DETAILS',
  SHIPPING_DETAILS_INT: 'SHIPPING_DETAILS_INT',
  REMOVE_SHIPPING: 'REMOVE_SHIPPING',
  TRANSIT_HOURS: 'TRANSIT_HOURS',
  IMPORT_DETAILS: 'IMPORT_DETAILS',
  // Manufactruing page is handled by MVS team, not by us.
  MANUFACTURING_DETAILS: 'MANUFACTURING_DETAILS',
  REVIEW_ADDRESS: 'REVIEW_ADDRESS',
  SANCTIONED_ADDRESS: 'SANCTIONED_ADDRESS',
  HOURS_AND_CLOSURES: 'HOURS_AND_CLOSURES',
  SECURITY_DETAILS: 'SECURITY_DETAILS',
  EDIT_LOCATION_NAME: 'EDIT_LOCATION_NAME',
  LOCATION_CONTACTS: 'LOCATION_CONTACTS',
}

/**
 * @enum
 * To determine which type of page is being requested in current page URL in order to make appropriate page visible to User.
 */
export const PAGE_URL_PARAM = {
  [PAGE_TYPE.ADD_LOCATION]: 'add',
  [PAGE_TYPE.EDIT_LOCATION]: 'editLoc',
  [PAGE_TYPE.VIEW_LOCATION]: 'viewLoc',
  [PAGE_TYPE.EDIT_ADDRESS]: 'editAddr',
  [PAGE_TYPE.SHIPPING_DETAILS]: 'editShip',
  [PAGE_TYPE.SHIPPING_DETAILS_INT]: 'editShipInt',
  [PAGE_TYPE.REMOVE_SHIPPING]: 'remShip',
  [PAGE_TYPE.LOCATION_READY]: 'locR',
  [PAGE_TYPE.LOCATION_TYPE_CAPABILITY]: 'editLocType',
  [PAGE_TYPE.REVIEW_ADDRESS]: 'reviewAddr',
  [PAGE_TYPE.SANCTIONED_ADDRESS]: 'sanctionAddr',
  [PAGE_TYPE.IMPORT_DETAILS]: 'impDet',
  [PAGE_TYPE.HOURS_AND_CLOSURES]: 'editClosure',
  [PAGE_TYPE.SECURITY_DETAILS]: 'secDet',
  [PAGE_TYPE.EDIT_LOCATION_NAME]: 'editLocName',
  [PAGE_TYPE.LOCATION_CONTACTS]: 'editLocCont',
}

/**
 * @enum - Countries Names which are considered as domestic
 */
export const DOMESTIC_COUNTRY_NAMES = {
  UNITED_STATES: 'US',
  CANADA: 'CA',
}

/**
 * @enum - Countries which are considered as domestic
 */
export const DOMESTIC_COUNTRY = [
  DOMESTIC_COUNTRY_NAMES.UNITED_STATES,
  DOMESTIC_COUNTRY_NAMES.CANADA,
]

/**
 * @enum - States names which are allowed to input prepaid details (transit hours)
 */
export const PREPAID_DETAILS_STATES_NAMES = {
  Alaska: 'US-AK',
  Hawaii: 'US-HI',
}

/**
 * @enum - States which are allowed to input prepaid details (transit hours)
 */
export const PREPAID_DETAILS_STATES = [
  PREPAID_DETAILS_STATES_NAMES.Alaska,
  PREPAID_DETAILS_STATES_NAMES.Hawaii,
]

/**
 * @enum - Available location types
 */
export const LOCATION_TYPE = {
  SHIPPING: 'SHIPPING',
  MANUFACTURING: 'MANUFACTURING',
  'BUSINESS OFFICE': 'BUSINESS OFFICE',
}

/**
 * @enum - Available location types based on URL Param
 * To determine which type of location type is being requested in current page URL in order to select appropriate location type.
 */
export const PARAM_LOCATION_TYPE = {
  ship: LOCATION_TYPE.SHIPPING,
  manufacture: LOCATION_TYPE.MANUFACTURING,
  busoff: LOCATION_TYPE['BUSINESS OFFICE'],
}

/**
 * @enum - Available location capability types
 */
export const LOCATION_CAP_TYPE = {
  LOADING_DOCK: 'LOADING_DOCK',
  IMPORTER: 'IMPORTER',
}

export const NATIONAL_BRAND_DEPARTMENTS = [211, 224, 878]

export const MATRICES_REQUIRED_SUPPLIER_TYPE = [
  1, 2, 3, 4, 9, 7, 10, 27, 15, 31,
]

export const TIER2_SUPPLIER_TYPES = [7, 10, 27, 31]

export const DUNS_BRANCH_ID = 9140

export const LAND_CARRIER_TYPES = [
  { id: 1, value: 'Domestic Asset' },
  { id: 2, value: 'Domestic Broker' },
  { id: 3, value: 'Import Asset' },
  { id: 4, value: 'Import Broker' },
]

export const TRANSPORTATION_CARRIER_TYPES = [
  { id: 5, value: 'Domestic' },
  { id: 6, value: 'Import/Overseas' },
]

export const TRANSPORTATION_TYPES = [1, 2, 3, 4, 9, 15]

export const SOURCES = [
  { value: 'Target Designated', label: 'Target Designated' },
  { value: 'Vendor Sourced', label: 'Vendor Sourced' },
]

export const DATA_MIGARTION = 'DATA-MIGRATION'

export const sccRequiredCOuntries = [
  'NZ',
  'JO',
  'JP',
  'KP',
  'KR',
  'TW',
  'IL',
  'MX',
  'SG',
  'DO',
  'PE',
  'CA',
  'US',
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
]

export const POTENTIAL_DUNS_REVIEW = 'Potential Duns Matches'

export const EDIT_DUNS_REVIEW = 'Duns Edit Requests'

export const Merch_Distributor_Invite_Text =
  'By selecting Yes, this vendor will be labeled as a Merchandise Vendor – Distributor.  This means that they will be providing product to Target on behalf of another brand owner.'

export const Brand_Owner_Only_Invite_Text =
  'Owner of a brand whose product is distributed by a third party'

export const duns_find_help_verbiage =
  "Select the 'I Don't know My Number' button above. Include these details:"
