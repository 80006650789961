import axios from 'axios'
import * as ACTIONS from './actionType'
import apiConfig from '../../config/apiConfig'
import { Notification } from '../../store/Notification/actionCreator'
// import { ALERTS } from '../../data/contact'
import {
  MERCH_VENDOR_OB,
  MERCH_VENDOR_NB,
  SALES_REP,
  INDIRECT,
  FACILITIES_MANAGEMENT,
  PD_PROVIDER,
  MARKETING,
  AIR_CARRIER,
  CUSTOMS_BROKER,
  CONSOLIDATOR,
  DECONSOLIDATOR,
  OCEAN_CARRIER,
  LAND_CARRIER,
  LAW_VENDOR,
  FABRIC,
  FACTOR,
  EDI,
  DATA,
  QA_SERVICE_PROVIDER,
  FRS_SERVICE_PROVIDER,
  DESIGN_PARTNER,
  BRAND_OWNER,
  THIRD_PARTY_LOGISTICS,
  PACKAGING,
} from '../../constants/dataFromTabAPIMock'
import { getTargetSetup } from './../Supplier/actionCreator'

export function getSupplierInfo(dispatch, supplierId) {
  let url = `${apiConfig.api.supplier}profiles?detailed_profile=true`
  if (supplierId) {
    url = `${apiConfig.api.supplier}${supplierId}?relationship=true`
  }

  return function (dispatch) {
    dispatch(supplierInfoInitiated())
    return axios
      .get(url, {
        data: {},
      })
      .then((response) => {
        if (response?.data) {
          let { data } = response
          const { dun_number } = data[0] ? data[0] : data
          const { supplier_id } = data[1] ? data[1] : data
          const id = supplier_id ? supplier_id : supplierId
          dispatch(getSupplierInfoByID(dispatch, dun_number, id))
          dispatch(preSupplierInfoSuccess(data))
        } else {
          const error = { status: 401 }
          dispatch(supplierInfoFail(error))
        }
      })
      .catch((error) => {
        dispatch(supplierInfoFail(error))
      })
  }
}
export function getSupplierInfoByID(dispatch, dun_number, id) {
  let url
  if (
    apiConfig.api.supplier?.includes('dev') ||
    apiConfig.api.supplier.includes('stage')
  ) {
    url = `${apiConfig.api.supplier}profiles?detailed_profile=true&supplier_id=${id}`
  } else {
    if (dun_number !== null && dun_number) {
      url = `${apiConfig.api.supplier}profiles?detailed_profile=true&dun_number=${dun_number}`
    } else {
      url = `${apiConfig.api.supplier}profiles?detailed_profile=true&supplier_id=${id}`
    }
  }
  return function (dispatch) {
    return axios
      .get(url, {
        data: {},
      })
      .then((response) => {
        let { data } = response
        dispatch(supplierInfoSuccess(data))
        const { supplier_id } = data
        if (supplier_id) {
          setSupplier(dispatch, supplier_id)
          dispatch(getAlertInfo(dispatch, supplier_id))
          dispatch(getTargetSetup(dispatch, supplier_id))
          dispatch(fetchPendingTodos(supplier_id))
          dispatch(fetchAllTodosStatusForSupplier(supplier_id))
        } else {
          const error = { status: 401 }
          dispatch(supplierInfoFail(error))
        }
      })
      .catch((error) => {
        dispatch(supplierInfoFail(error))
      })
  }
}

export function supplierInfoInitiated() {
  return {
    type: ACTIONS.TYPE.SUPPLIER_INFO_INIT,
  }
}
export function supplierInfoSuccess(payload) {
  return {
    type: ACTIONS.TYPE.SUPPLIER_INFO,
    payload,
  }
}
export function supplierInfoFail(error) {
  return {
    type: ACTIONS.TYPE.SUPPLIER_INFO_FAIL,
    error,
  }
}
export function preSupplierInfoSuccess(payload) {
  return {
    type: ACTIONS.TYPE.PRE_SUPPLIER_INFO,
    payload,
  }
}

export function getAlertInfo(dispatch, supplierId) {
  let url = `${apiConfig.api.aggregator}supplier/pending_actions`
  if (supplierId) {
    url = `${apiConfig.api.aggregator}supplier/pending_actions/${supplierId}`
  }
  return function (dispatch) {
    dispatch(alertInfoInitiated())
    return axios
      .get(url, {
        data: {},
      })
      .then((response) => {
        // const data = ALERTS
        const { data } = response
        let tempData = {}
        Object.keys(data).forEach((type) => {
          Object.keys(data?.[type]).forEach((key) => {
            if (tempData?.[key] === true) {
              tempData[key] = data[type]?.[key]
            }
            if (tempData?.[key] === undefined) {
              tempData[key] = data[type]?.[key]
            }
            if (key === 'supplier_type_status') {
              if (tempData[key]) {
                switch (tempData[key]) {
                  case 'Candidate':
                    if (
                      data[type]?.[key] === 'Under Review' ||
                      data[type]?.[key] === 'Approved' ||
                      data[type]?.[key] === 'Started'
                    ) {
                      tempData[key] = data[type]?.[key]
                    }
                    break
                  case 'Started':
                    if (
                      data[type]?.[key] === 'Under Review' ||
                      data[type]?.[key] === 'Approved'
                    ) {
                      tempData[key] = data[type]?.[key]
                    }
                    break
                  case 'Under Review':
                    if (data[type]?.[key] === 'Approved') {
                      tempData[key] = data[type]?.[key]
                    }
                    break
                  default:
                    break
                }
              }
            }
          })
        })
        dispatch(alertInfoSuccess(tempData))
      })
      .catch((error) => {
        dispatch(alertInfoFail(error))
      })
  }
}
export function alertInfoInitiated() {
  return {
    type: ACTIONS.TYPE.ALERT_INFO_INIT,
  }
}

export function alertInfoSuccess(payload) {
  return {
    type: ACTIONS.TYPE.ALERT_INFO,
    payload,
  }
}
export function alertInfoFail(error) {
  return {
    type: ACTIONS.TYPE.ALERT_INFO_FAIL,
    error,
  }
}
export function postMarkAsDone(value, supplierId) {
  return function (dispatch) {
    dispatch(markAsDoneInitiated())
    const putData = value
    let url = `${apiConfig.api.supplier}suppliers/${supplierId}`
    return axios
      .put(url, putData)
      .then((response) => {
        const { data } = response
        if (data) {
          if (data?.[0]?.result === 'Fail') {
            dispatch(markAsDoneFail(true))
            dispatch(
              Notification(
                true,
                'Add a "Brand" in VMM to complete this To-Do',
                true
              )
            )
          } else {
            dispatch(markAsDoneSuccess(data))
            dispatch(getAlertInfo(dispatch, supplierId))
            dispatch(fetchPendingTodos(supplierId))
            if (putData?.target_history) {
              dispatch(getTargetSetup(dispatch, supplierId))
            }
          }
        } else {
          dispatch(markAsDoneFail(true))
        }
      })
      .catch((e) => {
        dispatch(markAsDoneFail(e))
      })
  }
}

export function markAsDoneInitiated() {
  return {
    type: ACTIONS.TYPE.MARK_DONE_INIT,
  }
}
export function markAsDoneSuccess(payload) {
  return {
    type: ACTIONS.TYPE.MARK_DONE_INFO,
    payload,
  }
}
export function markAsDoneFail(error) {
  return {
    type: ACTIONS.TYPE.MARK_DONE_FAIL,
    error,
  }
}

export function getPaymentInfo(supplierId) {
  return function (dispatch) {
    dispatch(paymentInfoInitiated())
    let url = `${apiConfig.api.supplier}todos/payments/${supplierId}`
    return axios
      .get(url)
      .then((response) => {
        const { data } = response
        if (data) {
          const { data } = response
          dispatch(paymentInfoSuccess(data))
        } else {
          dispatch(markAsDoneFail(true))
        }
      })
      .catch((e) => {
        dispatch(paymentInfoFail(e))
      })
  }
}

export function paymentInfoInitiated() {
  return {
    type: ACTIONS.TYPE.PAYMENT_INFO_INIT,
  }
}
export function paymentInfoSuccess(data) {
  return {
    type: ACTIONS.TYPE.PAYMENT_INFO_INFO,
    data,
  }
}
export function paymentInfoFail(error) {
  return {
    type: ACTIONS.TYPE.PAYMENT_INFO_FAIL,
    error,
  }
}

export function resetProfile(dispatch) {
  dispatch({ type: ACTIONS.TYPE.SUPPLIER_PROFILE_RESET })
}

export function resetMarkAsDone(dispatch) {
  dispatch({ type: ACTIONS.TYPE.MARK_DONE_RESET })
}

export function setSupplier(dispatch, id) {
  dispatch({ type: ACTIONS.TYPE.SET_SUPPLIER_ID, id })
}
export function setSupplierToken(token) {
  return {
    type: ACTIONS.TYPE.SET_SUPPLIER_TOKEN,
    payload: token,
  }
}

export function getSupplierToken() {
  return function (dispatch) {
    let url = `${apiConfig.api.contact}user_token`
    return axios
      .get(url)
      .then((response) => {
        const { data } = response
        if (data?.token) {
          dispatch(setSupplierToken(data.token))
          localStorage.setItem('X-USER-TOKEN', data.token)
        }
      })
      .catch((e) => {
        // This will again call the api if it fails, but the question is should we do it? As of now, we have not implemented api retry functionality. And if we do like this, it will have infinite loop (if for some reason, api gets failed again and again)
        // dispatch(getSupplierToken())
      })
  }
}

export function getSupplierOverview(supplierId) {
  return function (dispatch) {
    let url = `${apiConfig.api.aggregator}supplier_overviews/${supplierId}`
    return axios
      .get(url)
      .then((response) => {
        const { data } = response
        dispatch({
          type: ACTIONS.TYPE.SUPPLIER_OVERVIEW,
          payload: data,
        })
        dispatch(fetchTabData(data?.target_setup?.supplier_types))
        window.localStorage.setItem('supplier_info', JSON.stringify(data))
      })
      .catch((e) => {})
  }
}

export function postCompanyOffice(value, supplierId) {
  const putData = value
  let url = `${apiConfig.api.contact}company_officers?supplier_id=${supplierId}`
  return function (dispatch) {
    dispatch(companyOfficerInitiated())
    return axios
      .put(url, putData)
      .then((response) => {
        const { data } = response
        dispatch(companyOfficerSuccess(data))
        dispatch(getSupplierInfo(dispatch, supplierId))
      })
      .catch((error) => {
        dispatch(companyOfficerFail(error))
      })
  }
}

export function postDiversityValues(value, supplierData) {
  const putData = value

  return function (dispatch) {
    let url = `${apiConfig.api.supplier}diversities/${supplierData.supplierInfo.supplier_id}`
    dispatch(companyOfficerInitiated())
    return axios
      .put(url, putData)
      .then((response) => {
        const { data } = response
        dispatch(companyOfficerSuccess(data))
        dispatch(
          getSupplierInfo(dispatch, supplierData.supplierInfo.supplier_id)
        )
      })
      .catch((error) => {
        dispatch(companyOfficerFail(error))
      })
  }
}

const companyOfficerInitiated = () => {
  return {
    type: ACTIONS.TYPE.PUT_COMPANY_INIT,
  }
}
const companyOfficerSuccess = (payload) => {
  return {
    type: ACTIONS.TYPE.PUT_COMPANY_INFO,
    payload,
  }
}
const companyOfficerFail = (error) => {
  return {
    type: ACTIONS.TYPE.PUT_COMPANY_FAIL,
    error,
  }
}

export function restCompanyOfficer() {
  return {
    type: ACTIONS.TYPE.REST_COMPANY_INFO,
  }
}

export const fetchCountryList = async () => {
  try {
    const response = await axios.get(`${apiConfig.api.masterData}countries`)
    return response?.data
  } catch (err) {
    return err
  }
}

export const fetchAddressFormatAndStates = async (countryCode) => {
  try {
    const addressFormatResponse = await axios.get(
      `${apiConfig.api.masterData}address_formats?country_code=${countryCode}`
    )

    const statesResponse = await axios.get(
      `${apiConfig.api.masterData}states?country_code=${countryCode}`
    )

    const [addressFormat, states] = await Promise.all([
      addressFormatResponse,
      statesResponse,
    ])

    return {
      addressFormat: addressFormat?.data,
      states: states?.data,
    }
  } catch (err) {
    return err
  }
}

export const fetchCityAndStatesByZipcode = async (countryCode, zipcode) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.validator}addresses?country_code=${countryCode}&postal_code=${zipcode}`
    )

    // We need to extract city and state only from informatica response, so picking it selectively
    return {
      city: response?.data[0]?.city || '',
      state_province_code: response?.data[0]?.state_province_code || '',
      state_province_name: response?.data[0]?.state_province_name || '',
    }
  } catch (err) {
    return err
  }
}

export const saveBasicInfo = async (data) => {
  try {
    const response = await axios.put(
      `${apiConfig.api.supplier}basic_information/${data.supplier_id}`,
      data
    )

    return response
  } catch (err) {
    return err
  }
}

export const fetchBasicInformationAuditData = async (
  supplierId,
  auditType,
  cancelToken
) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.supplier}basic_information_audits/${supplierId}`,
      {
        cancelToken: cancelToken?.token || '',
      }
    )
    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchPendingTodos = (supplier_id) => {
  const url = `${apiConfig.api.supplier}pending_actions/${supplier_id}`
  return (dispatch) => {
    dispatch({ type: ACTIONS.TYPE.OVERVIEW_TODOS_INIT })
    axios
      .get(url)
      .then((response) => {
        const { data } = response
        dispatch({ type: ACTIONS.TYPE.OVERVIEW_TODOS_INFO, payload: data })
        dispatch(getSupplierOverview(supplier_id))
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.TYPE.OVERVIEW_TODOS_FAIL, error })
      })
  }
}

export const fetchAllTodosStatusForSupplier = (supplierId) => {
  const url = `${apiConfig.api.supplier}todos/${supplierId}`
  return (dispatch) => {
    dispatch({ type: ACTIONS.TYPE.TODOS_STATUS_INIT })
    axios
      .get(url)
      .then((response) => {
        const { data } = response
        dispatch({ type: ACTIONS.TYPE.TODOS_STATUS_INFO, payload: data })
        if (
          response?.data?.meta_info?.error?.message ===
          'Supplier Todos do not exist'
        ) {
          dispatch({ type: ACTIONS.TYPE.TODOS_STATUS_FAIL })
        }
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.TYPE.TODOS_STATUS_FAIL, error })
      })
  }
}

const createTabMap = (ObjectKey, tabMap) => {
  Object.keys(ObjectKey).forEach((key) => {
    if (Array.isArray(ObjectKey[key]) && ObjectKey[key].length > 0) {
      ObjectKey[key].push.apply(ObjectKey[key], tabMap.get(key))
      tabMap.set(key, Array.from(new Set(ObjectKey[key])))
    } else {
      tabMap.set(key)
    }
  })
}
export const fetchTabData = (supplierTypes) => {
  return (dispatch) => {
    let tabMap = new Map()
    supplierTypes.forEach((supplierType) => {
      if (supplierType.supplier_type_id === 8) {
        let hasSomeType = false
        if (
          supplierType.supplier_sub_types.some(
            (subType) => subType.sub_type_id === 5
          )
        ) {
          hasSomeType = true
          createTabMap(MERCH_VENDOR_OB, tabMap)
        }
        if (
          supplierType.supplier_sub_types.some(
            (subType) => subType.sub_type_id === 7
          )
        ) {
          hasSomeType = true
          createTabMap(MERCH_VENDOR_NB, tabMap)
        }
        if (!hasSomeType) {
          createTabMap(MERCH_VENDOR_OB, tabMap)
        }
      } else if (supplierType.supplier_type_id === 16) {
        createTabMap(INDIRECT, tabMap)
      } else if (supplierType.supplier_type_id === 12) {
        createTabMap(SALES_REP, tabMap)
      } else if (supplierType.supplier_type_id === 17) {
        createTabMap(FACILITIES_MANAGEMENT, tabMap)
      } else if (supplierType.supplier_type_id === 20) {
        createTabMap(PD_PROVIDER, tabMap)
      } else if (supplierType.supplier_type_id === 21) {
        createTabMap(MARKETING, tabMap)
      } else if (supplierType.supplier_type_id === 1) {
        createTabMap(AIR_CARRIER, tabMap)
      } else if (supplierType.supplier_type_id === 2) {
        createTabMap(CUSTOMS_BROKER, tabMap)
      } else if (supplierType.supplier_type_id === 3) {
        createTabMap(CONSOLIDATOR, tabMap)
      } else if (supplierType.supplier_type_id === 4) {
        createTabMap(DECONSOLIDATOR, tabMap)
      } else if (supplierType.supplier_type_id === 9) {
        createTabMap(OCEAN_CARRIER, tabMap)
      } else if (supplierType.supplier_type_id === 15) {
        createTabMap(LAND_CARRIER, tabMap)
      } else if (supplierType.supplier_type_id === 28) {
        createTabMap(LAW_VENDOR, tabMap)
      } else if (supplierType.supplier_type_id === 7) {
        createTabMap(FABRIC, tabMap)
      } else if (supplierType.supplier_type_id === 18) {
        createTabMap(FACTOR, tabMap)
      } else if (supplierType.supplier_type_id === 11) {
        createTabMap(EDI, tabMap)
      } else if (supplierType.supplier_type_id === 25) {
        createTabMap(DATA, tabMap)
      } else if (supplierType.supplier_type_id === 19) {
        createTabMap(QA_SERVICE_PROVIDER, tabMap)
      } else if (supplierType.supplier_type_id === 26) {
        createTabMap(FRS_SERVICE_PROVIDER, tabMap)
      } else if (supplierType.supplier_type_id === 5) {
        createTabMap(DESIGN_PARTNER, tabMap)
      } else if (supplierType.supplier_type_id === 6) {
        createTabMap(BRAND_OWNER, tabMap)
      } else if (supplierType.supplier_type_id === 22) {
        createTabMap(THIRD_PARTY_LOGISTICS, tabMap)
      } else if (supplierType.supplier_type_id === 27) {
        createTabMap(PACKAGING, tabMap)
      } else {
        createTabMap(MERCH_VENDOR_OB, tabMap)
      }
    })

    tabMap.forEach((value, key) => {
      if (Array.isArray(value)) {
        tabMap.set(key, new Set(value))
      }
    })
    dispatch({ type: ACTIONS.TYPE.GET_SUPPLIER_TABS, payload: tabMap })
  }
}

export const fetchDiversityAuditData = async (supplierId) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.supplier}supplier_diversity_audits/${supplierId}`
    )
    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchEditDunsData = async (dunNumber) => {
  try {
    const response = await axios.get(
      `${apiConfig.api.external}sm_profile/${dunNumber}`
    )
    return Promise.resolve(response?.data)
  } catch (err) {
    return Promise.reject(err)
  }
}
